import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

export default defineStore('agent/staff', () => {
	const VueRoute = useRoute();

	const staff = ref(null);
	const isLoading = ref(false);

	async function get() {
		isLoading.value = true;
		staff.value = null;

		const result = await useApi('staff/', {
			v3Agent: true,
			agentID: VueRoute.params.agentID,
			message: 'There was an issue retrieving the staff account. Please try again later.',
		}).json();

		isLoading.value = false;
		staff.value = result.data?.value;
	}

	async function updatePermissions(newPermissions) {
		isLoading.value = true;

		try {
			await useApi('staff/permissions/', {
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				headers: { 'content-type': 'application/json' },
				message:
					'There was an issue with updating your staff settings. Please try again later.',
			}).put(JSON.stringify(newPermissions));
			await get();
		} catch (error) {
			console.error(error);
			throw error;
		} finally {
			isLoading.value = false;
		}
	}

	return {
		staff,
		isLoading,

		get,
		updatePermissions,
	};
});
