import { defineStore } from 'pinia';
import { useApi } from '@/composables/useApi';
import { ref, computed } from 'vue';

export default defineStore('config', () => {
	const config = ref(null);

	const domainTransactionsEnabled = computed(() => {
		return config.value?.domain_transactions_enabled;
	});
	const editorMessage = computed(() => {
		return config.value?.editor_message;
	});
	const demoDomains = computed(() => {
		return [...(config.value?.demo_domains ?? [])]?.sort();
	});
	const demoUsers = computed(() => {
		return config.value?.demo_users ?? [];
	});

	async function getSiteConfig() {
		config.value = null;
		const result = await useApi('api/v3/site_config/', {
			message: 'there was an issue retrieving site settings! Please try again later.',
		}).json();

		config.value = result?.data?.value;

		return Promise.resolve();
	}
	async function saveSiteConfig({ config }) {
		await useApi('api/v3/site_config/', {
			message: 'there was an issue saving site settings! Please try again later.',
		}).post({ ...config });

		await getSiteConfig();
		return Promise.resolve();
	}
	async function ensureSiteConfig() {
		if (!config.value) {
			await getSiteConfig();
		}
	}

	return {
		config,
		domainTransactionsEnabled,
		editorMessage,
		demoDomains,
		demoUsers,

		getSiteConfig,
		ensureSiteConfig,
		saveSiteConfig,
	};
});
