<template>
	<BaseDialog v-model="open" alert max-width="800" persistent>
		<h2 class="text-3xl font-bold">Updated Terms of Service</h2>

		<ProgressBar v-if="loading" dark text="Saving..." />
		<template v-else>
			<p>
				We have recently updated our Terms of Service and Privacy Policy. In order to
				continue using the MySFDomain website and its associated services, you MUST agree to
				our new Terms of Service. You may view both documents by clicking on the following
				links:
			</p>
			<p>
				<AppLink :href="TOS"> Terms Of Service </AppLink>
				<br />
				<AppLink :href="Privacy"> Privacy Policy </AppLink>
			</p>
			<p>
				If you have any questions, please reach out to our support team at
				<AppLink href="mailto:support@mysfdomain.com"> support@mysfdomain.com </AppLink>.
			</p>
		</template>

		<template #actions="{ closeDialog }">
			<BaseButton color="primary" :disabled="loading" @click="acceptTOS(closeDialog)">
				I Agree
			</BaseButton>
		</template>
	</BaseDialog>
</template>

<script setup>
import { ref } from 'vue';

import TOS from '@/assets/docs/MySFDomain_Terms_of_Service_v1.pdf';
import Privacy from '@/assets/docs/MySFDomain_Privacy_Doc_v1.pdf';

import useUserStore from '@/stores/user';

import { useApi } from '@/composables/useApi';

import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import AppLink from '@/components/ui/AppLink.vue';
import BaseDialog from '@/components/ui/BaseDialog';

const userStore = useUserStore();

const loading = ref(false);
const open = ref(true);

async function acceptTOS(closeDialog) {
	loading.value = true;

	try {
		await useApi(`api/v3/users/auth_info/`, {
			agent: false,
			message: 'There was an issue accepting your TOS! Please try again later.',
		}).put({ accept_tos: true });

		await userStore.getUserData();
		closeDialog(true);
	} catch (error) {
		console.error(error);
	}
}
</script>

<style scoped></style>
