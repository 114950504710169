import { createApp } from 'vue';
import App from '@/App.vue';

const app = createApp(App, { router, pinia });

// Pinia setup
import pinia from '@/plugins/pinia';
app.use(pinia);

// Vue router
import router from '@/router/router';
app.use(router);
router.app = app;

import '@/styles/fontAwesome';
app.component('FAIcon', FontAwesomeIcon);

// vue-announcer
import VueAnnouncer from '@vue-a11y/announcer';
app.use(VueAnnouncer);

// app version checker store
import useAppVersionStore from './stores/appVersion';

// vee-validate
import { setupValidation } from '@/validationSetup';
setupValidation();

import * as Sentry from '@sentry/vue';
import { reportingObserverIntegration } from '@sentry/integrations';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		// Vue,
		dsn: 'https://a059ccf0c5ec4a0499187e2de69da67f@sentry.io/1424454',
		trackComponents: true,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			reportingObserverIntegration({ types: ['crash', 'deprecation', 'intervention'] }),

			// Housekeeping: re-enable this once our error rate drops within quota limits
			// Sentry.replayIntegration(),
		],
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,

		tracePropagationTargets: ['mysfdomain.com', /^\//],
	});
	const appVersionStore = useAppVersionStore(pinia);
	appVersionStore.periodicallyCheckForNewVersion();

	// to use a store outside a component, we need to pass the pinia object in
	router.afterEach(() => {
		if (appVersionStore.foundNewVersion) {
			appVersionStore.updateApp();
		}
	});
}

import '@/styles/index.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

app.config.errorHandler = (error, vm, info) => {
	if (error.message === `Error: Error: Highcharts error #13: www.highcharts.com/errors/13/`) {
		console.warn(error.message);
		return;
	}
	console.error('Error:', error);
	console.error('Vue component:', vm);
	console.error('Additional info:', info);
};
app.mount('#app');
