<template>
	<section class="shrink-0 grow">
		<h3 class="text-lg">Demo Domains</h3>
		<hr class="w-full border-orange-500" />

		<p class="text-black">These domains will be editable by users listed here</p>

		<form class="flex items-baseline gap-2" @submit.prevent="addToList">
			<TextFieldInput
				v-model="domainSearch"
				dense
				label="Add a domain"
				placeholder="domain"
			/>
			<BaseButton outline dense type="submit">Add to list</BaseButton>
		</form>
		<ul class="mt-4 pl-0">
			<li
				v-for="domain in domains"
				:key="domain"
				class="flex items-center justify-between text-black"
			>
				<AppLink :href="`https://${domain}`" class="mr-auto">
					{{ domain }}
					<FAIcon icon="arrow-up-right-from-square" />
				</AppLink>

				<button type="button" @click="removeFromList(domain)">
					<FAIcon icon="times" />
				</button>
			</li>
		</ul>
	</section>
</template>

<script setup>
import { ref } from 'vue';
import AppLink from '@/components/ui/AppLink.vue';
import TextFieldInput from '@/components/ui/TextFieldInput';
import BaseButton from '@/components/ui/BaseButton';

const emit = defineEmits(['update:domains']);

const props = defineProps({
	domains: { type: Array, default: () => [] },
});

const domainSearch = ref('');
function addToList() {
	emit('update:domains', [...props.domains, domainSearch.value].sort());
	domainSearch.value = '';
}
function removeFromList(domain) {
	emit(
		'update:domains',
		props.domains.filter(d => d !== domain)
	);
}
</script>

<style scoped></style>
