<template>
	<div class="inline-flex items-center" :class="{ 'flex-col text-center': showText }">
		<div v-if="!showText" class="inline-flex items-center dark:text-gray-900">
			<slot />
			<BaseTooltip
				text="Click for info"
				:position="tooltipPosition"
				:tooltip-id="keyword"
				button-class="rounded-full ml-1"
				@click="dialog = true"
			>
				<FAIcon
					:icon="icon"
					:size="iconSize"
					class="text-blue-700 hover:text-blue-900 dark:text-blue-800 dark:hover:text-blue-700"
				/>
			</BaseTooltip>
		</div>

		<button
			v-else
			class="inline-flex flex-col text-center text-blue-800"
			:title="buttonTitle"
			@click="dialog = true"
		>
			<slot />
			<span class="flex items-center">
				<FAIcon class="mr-1" :size="iconSize" :icon="icon" />
				<span>Click for info</span>
			</span>
		</button>

		<BaseDialog v-model="dialog" :aria-label="keyword" dense max-width="700">
			<KeywordContent :dialog="dialog" :keyword="keyword" @close="dialog = false" />
		</BaseDialog>
	</div>
</template>

<script setup>
import { ref } from 'vue';

import KeywordContent from '@/components/glossary/KeywordContent';
import BaseTooltip from '@/components/ui/BaseTooltip';
import BaseDialog from '@/components/ui/BaseDialog';

defineProps({
	keyword: {
		required: true,
		type: String,
	},
	icon: {
		type: String,
		default: 'circle-info',
	},
	iconSize: {
		type: String,
		default: 'lg',
	},
	showText: {
		type: Boolean,
		default: true,
	},
	tooltipPosition: {
		type: String,
		default: 'top',
	},
	buttonTitle: {
		type: String,
		default: '',
	},
});

const dialog = ref(false);
</script>
