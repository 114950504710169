<template>
	<div
		v-if="isMirusUser"
		:class="[
			inline ? 'inline-flex' : 'flex',
			dense ? 'p-2' : 'p-4',
			column ? 'flex-col' : 'flex-row',
		]"
		class="relative min-w-fit grow-0 items-center gap-1 rounded-md border border-dashed border-orange-500 bg-orange-100 dark:border-orange-500 dark:bg-orange-800 dark:text-orange-100"
	>
		<div
			class="absolute left-2 top-0 -translate-y-1/2 rounded-full bg-slate-100 dark:bg-slate-800 px-1.5 py-0.5 text-[0.5rem] font-semibold font-mono uppercase leading-none tracking-wide dark:border-slate-500"
		>
			mirus only
		</div>

		<div class="flex items-center gap-1">
			<slot />
		</div>
	</div>
	<div v-else-if="$slots.fallback">
		<slot name="fallback" />
	</div>
</template>

<script setup>
import useUserStore from '@/stores/user';
import { storeToRefs } from 'pinia';
defineProps({
	inline: { type: Boolean, default: false },
	dense: { type: Boolean, default: false },
	column: { type: Boolean, default: false },
});
const userStore = useUserStore();
const { isMirusUser } = storeToRefs(userStore);
</script>

<style scoped></style>
