import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { formatISO, isDate } from 'date-fns';

import { useApi } from '@/composables/useApi';
import useAlertStore from '@/stores/alerts';

export default defineStore('agent/qlp', () => {
	const VueRoute = useRoute();

	const views = ref(null);
	const stats = ref([]);
	const isLoading = ref(false);

	async function getStats(start, end) {
		if (!isDate(start) || !isDate(end)) {
			throw 'Valid start and end dates required';
		}

		const { data } = await useApi(
			`campaigns/?${new URLSearchParams({
				start: formatISO(start).substring(0, 19),
				end: formatISO(end).substring(0, 19),
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue retrieving your QLP stats from the server! Please try again later.',
			}
		).json();
		stats.value = data.value;
	}

	async function getViews(start, end) {
		if (!isDate(start) || !isDate(end)) {
			throw 'Valid start and end dates required';
		}

		const { data } = await useApi(
			`campaigns/qlp_views/?${new URLSearchParams({
				start: formatISO(start).substring(0, 19),
				end: formatISO(end).substring(0, 19),
			})}`,
			{
				v3Agent: true,
				agentID: VueRoute.params.agentID,
				message:
					'There was an issue retrieving your QLP stats from the server! Please try again later.',
			}
		).json();
		views.value = data.value?.qlp_views;
	}

	return {
		views,
		stats,
		isLoading,
		getStats,
		getViews,
	};
});
