<template>
	<div class="bg-gray-200 p-4">
		<div class="flex gap-5">
			<div class="basis-3/5 rounded-md bg-white p-4 transition-all hover:shadow">
				<h4 class="text-2xl">Edit Contact</h4>
				<!-- Contact Form -->
				<form class="flex flex-col" @submit="updateContact">
					<div class="flex flex-col gap-3 py-5 xl:flex-row">
						<TextFieldInput
							v-model="email"
							:disabled="loading"
							class="grow"
							label="New Contact Email"
							placeholder="email@email.com"
							type="email"
						>
							<template #hint> format: name@domain.com </template>
							<template v-if="errors.email" #message>
								{{ errors.email }}
							</template>
						</TextFieldInput>

						<TextFieldInput
							v-model="phone"
							:disabled="loading"
							class="grow"
							clearable
							label="New Contact Phone"
							placeholder="2223334444"
							type="tel"
						>
							<template #hint> format: 2223334444 </template>
							<template v-if="errors?.phone" #message>
								{{ errors.phone }}
							</template>
						</TextFieldInput>
					</div>

					<CheckboxGroup
						v-model:values="contactTypes"
						name="contact-types"
						:options="CONTACT_OPTIONS"
						legend="Registrar contact types"
					/>

					<BaseButton
						color="primary"
						type="submit"
						:disabled="!meta.valid || !meta.dirty || loading"
						:loading="loading"
						class="self-end"
					>
						Update Contact(s) Details
					</BaseButton>
				</form>
			</div>
			<!-- Existing Contacts  -->
			<div class="basis-2/5 rounded-md bg-white transition-all hover:shadow">
				<ProgressBar v-if="loading" class="!h-8 p-4" />
				<BaseAlert v-else-if="!contacts" type="error" class="h-full">
					There was a problem loading up this domains contacts. Go ask a dev!
				</BaseAlert>
				<SortableTable
					v-else
					:headers="CONTACT_HEADERS"
					:items="contacts"
					title="Existing Contacts"
					show-title
					item-unique-key="contact_type"
				/>
			</div>
		</div>
		<div class="mt-4 flex gap-5">
			<!-- Authcode -->
			<div class="grow rounded-md bg-white p-4 transition-all hover:shadow">
				<ProgressBar v-if="loading" class="!h-8" />
				<div v-else class="flex flex-col items-center justify-evenly gap-4 xl:flex-row">
					<div v-if="authcode" class="flex flex-col">
						<span>
							<b>Authcode: </b>
							<code>{{ authcode }}</code>
						</span>
						<span> <b>Timestamp: </b> {{ authcodeTimestamp }} </span>
					</div>
					<div v-else><b>Authcode: </b> Unknown</div>

					<BaseButton color="primary" @click="refreshAuthcode()">
						Get Authcode
					</BaseButton>
				</div>
			</div>

			<!-- Auto Renew -->
			<div class="rounded-md bg-white p-4 transition-all hover:shadow">
				<ProgressBar v-if="loading" class="!h-8" />
				<div
					v-else
					class="flex h-full flex-col items-center justify-between gap-4 2xl:flex-row"
				>
					<div>
						<b>Auto Renew Status: </b>
						<span> {{ autorenewStatus }} </span>
					</div>
				</div>
			</div>

			<!-- transfer Lock  -->
			<div class="rounded-md bg-white p-4 transition-all hover:shadow">
				<ProgressBar v-if="loading" class="!h-8" />
				<div
					v-else
					class="flex h-full flex-col items-center justify-between gap-4 2xl:flex-row"
				>
					<div>
						<b>Transfer Lock Status: </b>
						<span> {{ lockStatus }} </span>
					</div>
					<BaseButton v-if="lock === true" color="yellow" @click="updateLock(false)">
						unlock
					</BaseButton>
					<BaseButton v-else-if="lock === false" color="blue" @click="updateLock(true)">
						lock
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { format } from 'date-fns';
import { useForm } from 'vee-validate';
import { array as yArray, object as yObject, string as yString } from 'yup';

import { useApi } from '@/composables/useApi';

import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import CheckboxGroup from '@/components/ui/CheckboxGroup.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import SortableTable from '@/components/ui/SortableTable.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
const CONTACT_OPTIONS = [{ value: 'admin' }, { value: 'billing' }, { value: 'technical' }];
const CONTACT_HEADERS = [
	{ text: 'Type', value: 'contact_type', sortable: false },
	{ text: 'Email', value: 'email', sortable: false },
	{ text: 'Phone', value: 'phone', sortable: false },
];

const props = defineProps({
	name: { type: String, required: true },
});

const loading = ref(true);

const authcode = ref(null);
const authcodeUpdatedAt = ref(null);
const contacts = ref([]);
const autorenew = ref(null);
const lock = ref(null);
const agentID = ref(null);

const { meta, defineField, errors, handleSubmit, handleReset } = useForm({
	initialValues: {
		email: '',
		phone: '',
		contactTypes: ['admin', 'billing', 'technical'],
	},
	validationSchema: yObject({
		email: yString().email().required(),
		phone: yString()
			.required()
			.matches(/^\d{10}$/),
		contactTypes: yArray().of(yString().oneOf(CONTACT_OPTIONS.map(o => o.value))),
	}),
});

const [email] = defineField('email', { label: `New Contact Email` });
const [phone] = defineField('phone', {});
const [contactTypes] = defineField('contactTypes');

const authcodeTimestamp = computed(() => format(new Date(authcodeUpdatedAt.value), 'PPPppp'));
const autorenewStatus = computed(() => {
	if (autorenew.value === null || autorenew.value === undefined) {
		return 'Unknown';
	}
	return autorenew.value ? 'Active' : 'Disabled';
});
const lockStatus = computed(() => {
	if (lock.value === null || lock.value === undefined) {
		return 'Unknown';
	}
	return lock.value ? 'Locked' : 'Unlocked';
});

async function refreshAuthcode() {
	loading.value = true;

	try {
		const { data } = await useApi(`domains/${props.name}/authcode/`, {
			v3Agent: true,
			agentID: agentID.value,
			message:
				'There was an issue getting the domains authcode data! Please try again later.',
		})
			.put()
			.json();

		authcode.value = data.value?.authcode;
		authcodeUpdatedAt.value = data.value?.authcodeUpdatedAt;
	} finally {
		loading.value = false;
	}
}
const updateContact = handleSubmit(async () => {
	loading.value = true;

	try {
		const { data } = await useApi(`domains/${props.name}/registrar/`, {
			v3Agent: true,
			agentID: agentID.value,
			message: 'There was an issue updating the registrar contact email',
		})
			.post({
				email: email.value,
				phone: phone.value,
				contactTypes: contactTypes.value,
			})
			.json();

		contacts.value = data.value?.contacts;
		handleReset();
	} finally {
		loading.value = false;
	}
});

async function updateLock(newLock) {
	loading.value = true;
	try {
		const { data } = await useApi(`domains/${props.name}/lock/`, {
			v3Agent: true,
			agentID: agentID.value,
			message:
				'There was an issue getting the domains authcode data! Please try again later.',
		})
			.put({ lock: newLock })
			.json();
		lock.value = data.value?.lock;
	} finally {
		loading.value = false;
	}
}

onMounted(async () => {
	agentID.value = useRoute().params.agentID;
	try {
		const { data } = await useApi(`domains/${props.name}/registrar/`, {
			v3Agent: true,
			agentID: agentID.value,
			message:
				'There was an issue getting the domains registrar data! Please try again later.',
		}).json();

		authcode.value = data.value?.authcode;
		authcodeUpdatedAt.value = data.value?.authcodeUpdatedAt;
		autorenew.value = data.value?.autorenew;
		lock.value = data.value?.lock;
		contacts.value = data.value?.contacts;
	} finally {
		loading.value = false;
	}
});
</script>
